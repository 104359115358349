import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Header from '../components/Header';
import Footer from '../components/Footer';
import Reboot from '../style/reboot';
import Global from '../style/global';
import Grid from '../style/grid';
import ModalContext from '../store/modalContext';
import Modal from '../components/Modal';
import Logo from '../components/Logo';
import { colors, z } from '../consts/style';
import mq from '../style/mq';

const SiteWrapper = styled.div`
  background: ${colors.blue};
`;

const SiteBorder = styled.div`
  background: ${colors.blue};
`;

const Top = styled(SiteBorder)`
  position: fixed;
  top: 0;
  left: 0;
  height: 1.5rem;
  width: 100%;
  z-index: ${z.border};
  ${mq.mobile`
    height: .8rem;
  `}
`;

const Left = styled(SiteBorder)`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 1.5rem;
  z-index: ${z.border};
  ${mq.tabletSmall`
  display: none;
`}
`;

const Right = styled(SiteBorder)`
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 1.5rem;
  z-index: ${z.border};
  ${mq.tabletSmall`
  display: none;
`}
`;

const Layout = ({ children, location }) => {
  // useEffect(() => {
  //   if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
  //     window.navigator.serviceWorker.getRegistrations().then(registrations => {
  //       registrations.forEach(r => r.unregister());
  //     });
  //   }
  // }, []);
  const insertBorder = () => (
    <Fragment>
      <Top />
      <Left />
      <Right />
    </Fragment>
  );
  return (
    <SiteWrapper id="top">
      <Reboot />
      <Global />
      <Grid />
      {insertBorder()}
      <Logo location={location} />
      <Header location={location} />
      {children}
      <Footer />
      <ModalContext.Consumer>
        {({ open }) => {
          return <Modal open={open} />;
        }}
      </ModalContext.Consumer>
    </SiteWrapper>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object,
};

export default Layout;
