import React, { Fragment, useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import _map from 'lodash/map';

import { Container } from './Elements';
import Socials from './Socials';
import Copyright from './Copyright';
import Intro from './Intro';
import { colors, z, font, bz, boxShadow, fontOffset } from '../consts/style';
import mq from '../style/mq';
import { header } from '../consts/data';
import { ScrollToInquire, ScrollTopNow } from '../utils/helpers';
import heroContext from '../store/heroContext';
import MenuIcon from './MenuIcon';

const Wrapper = styled.div`
  height: 8rem;
  position: fixed;
  top: 3.2rem;
  left: 0;
  width: 100%;
  z-index: ${z.header};
  background: ${(props) => (props.scrolled ? 'white' : 'transparent')};
  box-shadow: ${(props) => (props.scrolled ? boxShadow.default : 'none')};
  ${mq.tabletSmall`
  height: 8rem;
  top: 3.2rem;
  `}
  ${mq.mobile`
  height: 6rem;
  top: 3.2rem;
  `}
  ${bz};
  ${Container} {
    height: 8rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 1.5rem;
    ${mq.tabletSmall`
      padding-top: 1.2rem;
    `}
    ${mq.mobile`
      height: 6rem;
      padding-top: .8rem;
    `}
  }
`;

const Inquire = styled.button`
  ${font.pCaps};
  color: ${colors.orange};
  letter-spacing: 0.3em;
  font-weight: bold;
  background: transparent;
  border: none;
  padding: 0;
  ${fontOffset};
  ${mq.mobile`
    font-size: 1.4rem;
  `}
  position: relative;
  &:hover {
    &:after {
      width: 100%;
    }
  }
  &:after {
    transition: 0.5s all;
    ${bz};
    content: '';
    background: ${colors.orange};
    height: 0.2rem;
    width: 0%;
    position: absolute;
    left: 0;
    bottom: 0.1rem;
  }
`;

const Shelf = styled.div`
  background: ${colors.blue};
  width: 50%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: ${z.shelf};
  transition: transform 0.5s;
  transform: translateX(${(props) => (props.open ? '0' : '100%')});
  ${bz};
  ${mq.tablet`
    width: 100%;
  `}
  ${Container} {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    height: 100%;
    .footer-wrap {
      height: 25%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
      padding-bottom: 3rem;
      .social-wrap {
        margin-bottom: 3rem;
      }
      ${mq.tabletSmall`
      align-items: center;
      text-align: center;
      width: 100%;
      .social-wrap {
        margin-bottom: 6rem;
      }
    `}
    }
    .menu-wrap {
      ${mq.tabletSmall`
        align-items: center;
        text-align: center;
        width: 100%;
        height: 75%;
      `}
      height: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      text-align: left;
      a,
      button {
        ${font.menu};
        padding: 0;
        background: transparent;
        border: none;
        text-align: left;
        margin-bottom: 3.2rem;
        &:hover {
          text-decoration: none;
        }
        position: relative;
        &:hover {
          &:after {
            width: 100%;
          }
        }
        &:after {
          transition: 0.5s all;
          ${bz};
          content: '';
          background: white;
          height: 0.2rem;
          width: 0%;
          position: absolute;
          left: 0;
          bottom: 0rem;
        }
      }
    }
  }
`;

const Banner = styled.div`
  height: 3.2rem;
  position: fixed;
  top: 1.5rem;
  left: 0;
  width: 100%;
  background: ${colors.orange};
  text-align: center;
  padding: 0 2rem;
  z-index: ${z.header + 1};
  color: white;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  ${mq.mobile`
   top: .8rem;
  `}
  span {
    ${font.small};
    ${fontOffset};
    letter-spacing: 0.2em;
  }
`;

const Header = ({ location }) => {
  const { shown, unfade, fade, hide, show } = useContext(heroContext);
  const [introHidden, setIntroHidden] = useState(false);
  const [shelfOpen, setShelfOpen] = useState(false);
  const isNotHome = location && location.pathname !== '/';
  const shouldHaveScrollStyles = () => {
    if (isNotHome || !shown) return true;
    return false;
  };
  const onHideIntro = () => setIntroHidden(true);

  useEffect(() => {
    setShelfOpen(false);
    show();
    unfade();
  }, [location]);

  function handleInquireClick() {
    if (!isNotHome && shown) {
      ScrollTopNow();
      fade();
      setShelfOpen(false);
      setTimeout(() => {
        ScrollToInquire();
        hide();
      }, 900);
    } else {
      setShelfOpen(false);
      ScrollToInquire();
    }
  }

  function getMenuItems() {
    return _map(header.menuItems, (item, i) => {
      const linkProps = {
        to: item.link,
        key: i,
      };
      return item.link ? (
        <Link {...linkProps}>{item.title}</Link>
      ) : (
        <button key={i} onClick={handleInquireClick}>
          {item.title}
        </button>
      );
    });
  }

  return (
    <Fragment>
      {!introHidden && <Intro onClear={onHideIntro} />}
      <Banner>
        <span>Pricing & HOA Information: 858.405.9270</span>
      </Banner>
      <MenuIcon onClick={() => setShelfOpen(!shelfOpen)} open={shelfOpen} />
      <Wrapper scrolled={shouldHaveScrollStyles()}>
        <Container>
          <Inquire onClick={!isNotHome ? handleInquireClick : ScrollToInquire}>
            Inquire
          </Inquire>
        </Container>
      </Wrapper>
      <Shelf open={shelfOpen}>
        <Container>
          <div className="menu-wrap">{getMenuItems()}</div>
          <div className="footer-wrap">
            <Socials />
            <Copyright />
          </div>
        </Container>
      </Shelf>
    </Fragment>
  );
};

Header.propTypes = {
  location: PropTypes.object.isRequired,
};

export default Header;
