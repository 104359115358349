import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ModalContext from '../store/modalContext';

import { font, z } from '../consts/style';

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${z.modalWrapper};
  padding: 2rem;
  cursor: pointer;
`;

export const ModalInner = styled.div`
  background: white;
  z-index: ${z.modalInner};
  position: relative;
  max-width: 100%;
  max-height: 100%;
  height: 25rem;
  width: 30rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h3 {
    ${font.h3};
  }
`;

const Modal = ({ open }) => {
  return (
    <ModalContext.Consumer>
      {({ closeModal }) =>
        open ? (
          <ModalWrapper onClick={closeModal}>
            <ModalInner>
              <h3>Submitted!</h3>
              <p>We&apos;ll be in touch with you shortly.</p>
            </ModalInner>
          </ModalWrapper>
        ) : null
      }
    </ModalContext.Consumer>
  );
};

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default Modal;
