import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { font } from '../consts/style';

const CopyWrapper = styled.div`
  color: white;
  ${font.small};
  a {
    color: white;
    text-decoration: none;
    &:hover {
      color: white;
      text-decoration: none;
    }
  }
`;

export default function Copyright() {
  const today = new Date();
  const year = today.getFullYear();

  return (
    <CopyWrapper>
      <p>
        &copy; Copyright {year} PENROSE |{' '}
        <Link to="/privacy">Privacy Policy</Link> |{' '}
        <Link to="/terms">Terms of Use</Link>
      </p>
    </CopyWrapper>
  );
}
