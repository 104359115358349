import React, { useState } from 'react';
import styled from 'styled-components';

import { colors } from '../../consts/style';

const Icon = styled.svg`
  transition: 0.3s all;
  transform: rotate(180deg);
  * {
    transition: 0.3s all;
  }
`;

export default function UpArrow() {
  const [hovered, setHovered] = useState(false);
  return (
    <Icon
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="25"
        cy="25"
        r="19.5"
        stroke={hovered ? colors.orange : 'white'}
        fill={hovered ? colors.orange : 'transparent'}
      />
      <path
        d="M33.1746 21.9246L25 30.0992L16.8254 21.9246"
        stroke={hovered ? 'white' : colors.orange}
        strokeWidth="2"
      />
    </Icon>
  );
}
