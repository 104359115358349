import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import Copyright from './Copyright';
import Socials from './Socials';
import { Container } from './Elements';

import { colors, z, font, bz } from '../consts/style';
import { ScrollTop, ScrollToInquire } from '../utils/helpers';
import LogoSrc from '../images/logo-orange.svg';
import mq from '../style/mq';
import UpArrow from './Icons/UpArrow';

export const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  background: ${colors.blue};
  z-index: ${z.footer};
  color: white;

  ${Container} {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36rem;
    ${mq.tablet`
      flex-wrap: wrap;
    `}
    ${mq.mobile`
    height: 55.3rem;
    flex-direction: column;
    `}
  }
`;

const Section = styled.div`
  width: 25%;
  padding: 1.2rem;
  ${mq.tablet`
    width: 33.333333334%;
    &.social {
      width: 100%;
      margin-bottom: 1.8rem;
    }
  `}
  ${mq.mobile`
  width: 100%;
  &.social {
    margin-top: 3rem;
  }
  &.logo {
    margin-bottom: 5rem;
  }
  `}
  &.text {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    ${mq.mobile`
    padding-bottom: 1rem;
    `}
    div {
      width: 12rem;
      text-align: left;
      margin-bottom: 0.8rem;
      span {
        position: relative;
        &:hover {
          &:after {
            width: 100%;
          }
        }
        &:after {
          transition: 0.5s all;
          ${bz};
          content: '';
          background: white;
          height: 0.2rem;
          width: 0%;
          position: absolute;
          left: 0;
          bottom: -0.3rem;
        }
      }
      ${mq.mobile`
        text-align: center;
      `}
    }
    a,
    button {
      ${font.small};
      text-transform: uppercase;
      color: white;
      text-decoration: none;
      background: none;
      padding: 0;
      border: none;
      letter-spacing: 0.2em;
      margin-bottom: 1.2rem;
      &:last-child {
        margin-bottom: 0;
      }
      ${mq.mobile`
      margin-bottom: 2rem;
      &:last-child {
        margin-bottom: 0;
      }
      `}
    }
  }
`;

const CopyrightWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  padding-bottom: 1.2rem;
  ${mq.mobile`
  left: 3rem;
  width: 44%;
  text-align: left;
  `}
`;

const ScrollUp = styled.button`
  position: absolute;
  bottom: 1.8rem;
  right: 1.8rem;
  background: transparent;
  border: none;
`;

const Logo = styled.img`
  width: 18rem;
  max-width: 100%;
  margin: 0 auto;
`;

const Footer = () => {
  return (
    <Wrapper>
      <Container>
        <Section className="logo">
          <Logo alt="Logo" src={LogoSrc} />
        </Section>
        <Section className="text">
          <div>
            <Link to="/" title="Home">
              <span>Home</span>
            </Link>
          </div>
          <button onClick={ScrollToInquire}>
            <div>
              <span>Inquire</span>
            </div>
          </button>
        </Section>
        <Section className="text">
          <div>
            <Link to="/residences" title="Residences">
              <span>Residences</span>
            </Link>
          </div>
          <div>
            <Link to="/lifestyle" title="Lifestyle">
              <span>Lifestyle</span>
            </Link>
          </div>
        </Section>
        <Section className="social">
          <Socials />
        </Section>
        <CopyrightWrapper>
          <Copyright />
        </CopyrightWrapper>
        <ScrollUp onClick={ScrollTop}>
          <UpArrow />
        </ScrollUp>
      </Container>
    </Wrapper>
  );
};

export default Footer;
