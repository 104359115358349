import bp from './breakpoints';

export const bz = `
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  `;

const mq = {
  mobile: `${bp.mobile}px`,
  tablet: `${bp.tablet}px`,
  tabletSmall: `${bp.tabletSmall}px`,
  desktop: `${bp.desktop}px`,
};

export const colors = {
  dark: '#000',
  light: '#fff',
  grey: '#DBDFE8',
  greyLight: '#A3A3A3',
  orange: '#E86D2C',
  blue: '#001C38',
  error: '#d42b23',
};

export const fontOffset = `
top: .24rem;
position: relative;
`;
export const headingOffset = `
top: -.5rem;
position: relative;
`;

export const font = {
  h1: `
        font-family: "Canela Light", sans-serif;
        font-size: 6rem;
        line-height: 150%;
        font-weight: normal;
        @media screen and (max-width: ${mq.tablet}) {
            font-size: 4.8rem;
        }
        @media screen and (max-width: ${mq.tabletSmall}) {
          font-size: 4.5rem;
        }
    `,
  h2: `
        font-family: "Canela Light", sans-serif;
        font-size: 4.5rem;
        line-height: 150%;
        font-weight: normal;
        @media screen and (max-width: ${mq.tablet}) {
            font-size: 3.7rem;
            line-height: 150%;
        }
        @media screen and (max-width: ${mq.tabletSmall}) {
          font-size: 3rem;
        }
    `,
  h3: `
        font-family: "Canela Light", sans-serif;
        font-size: 3rem;
        line-height: 150%;
        font-weight: normal;
        @media screen and (max-width: ${mq.tablet}) {
            font-size: 2.4rem;
        }
    `,
  menu: `
    font-family: "Calibre", sans-serif;
    font-size: 2rem;
    line-height: 150%;    
    text-transform: uppercase; 
    letter-spacing: .2em;
    color: white;
    font-weight: normal;
  `,
  small: `
    font-family: "Calibre", sans-serif;
    font-size: 1.4rem;
    line-height: 150%;   
    font-weight: normal;
    @media screen and (max-width: ${mq.tablet}) {
      font-size: 1.2rem;
    }  
`,
  label: `
  font-family: "Calibre", sans-serif;
  font-size: 1.4rem;
  line-height: 150%;   
  font-weight: normal;
  text-transform: uppercase;
  color: ${colors.greyLight};
  letter-spacing: .3em;
  `,
  p: `
    font-family: "Calibre", sans-serif;
    font-size: 1.8rem;
    line-height: 150%;     
    font-weight: normal;
  `,
  pCaps: `
    font-family: "Calibre", sans-serif;
    font-size: 1.8rem;
    line-height: 150%;     
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: .3em;
  `,
  pDisplay: `
    font-family: "Calibre", sans-serif;
    font-size: 2.4rem;
    line-height: 160%;     
    font-weight: normal;
    @media screen and (max-width: ${mq.tablet}) {
      font-size: 2rem;
    }
    @media screen and (max-width: ${mq.mobile}) {
      font-size: 1.8rem;
    }
  `,
  button: `
    font-family: "Calibre", sans-serif;
    font-size: 1.8rem;
    line-height: 100%;   
    text-transform: uppercase;
    letter-spacing: .2em;
    border-radius: 0;
    background: ${colors.orange};
    color: white;
    padding: 1.3rem 3.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    span {
      ${fontOffset};
    }
    @media screen and (max-width: ${mq.mobile}) {
      width: 100%;
    }
    `,
};

export const sliderHeight = `
  height: 45vw;
`;
export const sliderHeightLarge = `
  height: 50vw;
`;

export const z = {
  footer: -1,
  hero: 3000,
  header: 4000,
  border: 5000,
  intro: 10000,
  modalInner: 11000,
  modalWrapper: 12000,
  logo: 13000,
  shelf: 14000,
  icon: 14500,
  zoom: 15000,
  videoModal: 16000,
};

export const boxShadow = {
  default: '0px 4px 6px rgba(226, 226, 226, 0.25)',
};

export const duration = 500;
