import scrollToElement from 'scroll-to-element';

export const ScrollTop = () =>
  scrollToElement('#top', {
    offset: 0,
    duration: 1000,
  });

export const ScrollTopNow = () => {
  if (typeof document !== 'undefined') {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
};

export const ScrollToInquire = () =>
  scrollToElement('#inquire', {
    offset: -80,
    duration: 2000,
  });
