import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { colors, z, bz } from '../consts/style';
import mq from '../style/mq';

const Wrapper = styled.div`
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${z.intro};
  padding: ${(props) => (props.popped ? 0 : '4rem')};
  opacity: ${(props) => (props.faded ? 0 : 1)};
  transition: all 1s;
  ${bz};
  ${mq.tabletSmall`
  padding: ${(props) => (props.popped ? 0 : '2rem')};
  `}
`;

const Inner = styled.div`
  height: 100%;
  width: 100%;
  border: 1.5rem solid ${colors.blue};
  border-bottom-width: ${(props) => (props.faded ? '0rem' : '1.5rem')};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 1s;
  ${bz};
  ${mq.tabletSmall`
    border: 1.2rem solid ${colors.blue};
  `}
  ${mq.mobile`
    border: .8rem solid ${colors.blue};
  `}
`;

export default function Intro({ onClear }) {
  const [popped, setPopped] = useState(false);
  const [faded, setFaded] = useState(false);

  useEffect(() => {
    const pop = setTimeout(() => {
      setPopped(true);
    }, 1500);
    const fade = setTimeout(() => {
      setFaded(true);
    }, 2500);
    const hide = setTimeout(() => {
      onClear();
    }, 3500);
    if (typeof window !== 'undefined') {
      window.onload = () => {
        return () => clearTimeout([pop, fade, hide]);
      };
    }
  }, []);

  return (
    <Wrapper popped={popped} faded={faded}>
      <Inner faded={faded}></Inner>
    </Wrapper>
  );
}

Intro.propTypes = {
  onClear: PropTypes.func.isRequired,
};
