import React from 'react';
import PropTypes from 'prop-types';

const defaultState = {
  locked: false,
  fading: false,
  shown: true,
};

const HeroContext = React.createContext(defaultState);

class HeroProvider extends React.Component {
  state = {
    locked: false,
    fading: false,
    shown: true,
  };

  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  lock = () => {
    this.setState({ locked: true });
  };

  unlock = () => {
    this.setState({ locked: false });
  };

  show = () => {
    this.setState({ shown: true });
  };

  hide = () => {
    this.setState({ shown: false });
  };

  fade = () => {
    this.setState({ fading: true });
  };
  unfade = () => {
    this.setState({ fading: false });
  };

  render() {
    const { children } = this.props;
    const { locked, fading, shown } = this.state;
    return (
      <HeroContext.Provider
        value={{
          locked,
          fading,
          shown,
          lock: this.lock,
          unlock: this.unlock,
          show: this.show,
          hide: this.hide,
          fade: this.fade,
          unfade: this.unfade,
        }}
      >
        {children}
      </HeroContext.Provider>
    );
  }
}

export default HeroContext;

export { HeroProvider };
