import './static/fonts/fonts.css';
import './static/css/plyr.css';
import React from 'react';
import { ModalProvider } from './src/store/modalContext';
import { HeroProvider } from './src/store/heroContext';

// eslint-disable-next-line react/prop-types
export const wrapRootElement = ({ element }) => {
  return (
    <HeroProvider>
      <ModalProvider>{element}</ModalProvider>
    </HeroProvider>
  );
};
