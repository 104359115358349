import { createGlobalStyle } from 'styled-components';
import bp from '../consts/breakpoints';

const mq = {
  mobile: `${bp.mobile}px`,
  tablet: `${bp.tablet}px`,
  tabletSmall: `${bp.tabletSmall}px`,
  desktop: `${bp.desktop}px`,
};

const Grid = createGlobalStyle`
/**
 * GUTTERS
 */

 .row {
   display: flex;
   @media screen and (max-width: ${mq.mobile}) {
    flex-wrap: wrap;
   }
 }
.row [class*='col-'] {
  padding-right: 2rem;
}
.row [class*='col-']:last-of-type {
  padding-right: 0;
}

/**
 * UTIL CLASSES
 */

.jcc {
  justify-content: center;
} 

.aic {
  align-items: center;
}

.fww {
  flex-wrap: wrap;
}

/**
 * ROW CLASSES
 */

 .col-m-12 {
   @media screen and (max-width: ${mq.mobile}) {
    width: 100%;
   }
 }
 .col-m-11 {
   @media screen and (max-width: ${mq.mobile}) {
    width: 91.66%;
   }
 }
 .col-m-10 {
   @media screen and (max-width: ${mq.mobile}) {
    width: 83.33%
   }
 }
 .col-m-9 {
   @media screen and (max-width: ${mq.mobile}) {
    width: 75%
   }
 }
 .col-m-8 {
   @media screen and (max-width: ${mq.mobile}) {
    width: 66.66%
   }
 }
 .col-m-7 {
   @media screen and (max-width: ${mq.mobile}) {
    width: 58.33%
   }
 }
 .col-m-6 {
   @media screen and (max-width: ${mq.mobile}) {
    width: 50%
   }
 }
 .col-m-5 {
   @media screen and (max-width: ${mq.mobile}) {
    width: 41.66%
   }
 }
 .col-m-4 {
   @media screen and (max-width: ${mq.mobile}) {
    width: 33.33%
   }
 }
 .col-m-3 {
   @media screen and (max-width: ${mq.mobile}) {
    width: 25%
   }
 }
 .col-m-2 {
   @media screen and (max-width: ${mq.mobile}) {
    width: 16.66%
   }
 }
 .col-m-1 {
   @media screen and (max-width: ${mq.mobile}) {
    width: 8.33%
   }
 }

 .col-ts-12 {
   @media screen and (min-width: ${mq.mobile}) and (max-width: ${mq.tabletSmall}) {
    width: 100%;
   }
 }
 .col-ts-11 {
   @media screen and (min-width: ${mq.mobile}) and (max-width: ${mq.tabletSmall}) {
    width: 91.66%;
   }
 }
 .col-ts-10 {
   @media screen and (min-width: ${mq.mobile}) and (max-width: ${mq.tabletSmall}) {
    width: 83.33%
   }
 }
 .col-ts-9 {
   @media screen and (min-width: ${mq.mobile}) and (max-width: ${mq.tabletSmall}) {
    width: 75%
   }
 }
 .col-ts-8 {
   @media screen and (min-width: ${mq.mobile}) and (max-width: ${mq.tabletSmall}) {
    width: 66.66%
   }
 }
 .col-ts-7 {
   @media screen and (min-width: ${mq.mobile}) and (max-width: ${mq.tabletSmall}) {
    width: 58.33%
   }
 }
 .col-ts-6 {
   @media screen and (min-width: ${mq.mobile}) and (max-width: ${mq.tabletSmall}) {
    width: 50%
   }
 }
 .col-ts-5 {
   @media screen and (min-width: ${mq.mobile}) and (max-width: ${mq.tabletSmall}) {
    width: 41.66%
   }
 }
 .col-ts-4 {
   @media screen and (min-width: ${mq.mobile}) and (max-width: ${mq.tabletSmall}) {
    width: 33.33%
   }
 }
 .col-ts-3 {
   @media screen and (min-width: ${mq.mobile}) and (max-width: ${mq.tabletSmall}) {
    width: 25%
   }
 }
 .col-ts-2 {
   @media screen and (min-width: ${mq.mobile}) and (max-width: ${mq.tabletSmall}) {
    width: 16.66%
   }
 }
 .col-ts-1 {
   @media screen and (min-width: ${mq.mobile}) and (max-width: ${mq.tabletSmall}) {
    width: 8.33%
   }
 }


 .col-t-12 {
   @media screen and (min-width: ${mq.tabletSmall}) and (max-width: ${mq.tablet}) {
    width: 100%;
   }
 }
 .col-t-11 {
   @media screen and (min-width: ${mq.tabletSmall}) and (max-width: ${mq.tablet}) {
    width: 91.66%;
   }
 }
 .col-t-10 {
   @media screen and (min-width: ${mq.tabletSmall}) and (max-width: ${mq.tablet}) {
    width: 83.33%
   }
 }
 .col-t-9 {
   @media screen and (min-width: ${mq.tabletSmall}) and (max-width: ${mq.tablet}) {
    width: 75%
   }
 }
 .col-t-8 {
   @media screen and (min-width: ${mq.tabletSmall}) and (max-width: ${mq.tablet}) {
    width: 66.66%
   }
 }
 .col-t-7 {
   @media screen and (min-width: ${mq.tabletSmall}) and (max-width: ${mq.tablet}) {
    width: 58.33%
   }
 }
 .col-t-6 {
   @media screen and (min-width: ${mq.tabletSmall}) and (max-width: ${mq.tablet}) {
    width: 50%
   }
 }
 .col-t-5 {
   @media screen and (min-width: ${mq.tabletSmall}) and (max-width: ${mq.tablet}) {
    width: 41.66%
   }
 }
 .col-t-4 {
   @media screen and (min-width: ${mq.tabletSmall}) and (max-width: ${mq.tablet}) {
    width: 33.33%
   }
 }
 .col-t-3 {
   @media screen and (min-width: ${mq.tabletSmall}) and (max-width: ${mq.tablet}) {
    width: 25%
   }
 }
 .col-t-2 {
   @media screen and (min-width: ${mq.tabletSmall}) and (max-width: ${mq.tablet}) {
    width: 16.66%
   }
 }
 .col-t-1 {
   @media screen and (min-width: ${mq.tabletSmall}) and (max-width: ${mq.tablet}) {
    width: 8.33%
   }
 }

 .col-12 {
  @media screen and (min-width: ${mq.tablet}) {
    width: 100%;
   }
 }
 .col-11 {
   @media screen and (min-width: ${mq.tablet}) {
    width: 91.66%
   }
 }
 .col-10 {
   @media screen and (min-width: ${mq.tablet}) {
    width: 83.33%
   }
 }
 .col-9 {
   @media screen and (min-width: ${mq.tablet}) {
    width: 75%
   }
 }
 .col-8 {
   @media screen and (min-width: ${mq.tablet}) {
    width: 66.66%
   }
 }
 .col-7 {
   @media screen and (min-width: ${mq.tablet}) {
    width: 58.33%
   }
 }
 .col-6 {
   @media screen and (min-width: ${mq.tablet}) {
    width: 50%
   }
 }
 .col-5 {
   @media screen and (min-width: ${mq.tablet}) {
    width: 41.66%
   }
 }
 .col-4 {
   @media screen and (min-width: ${mq.tablet}) {
    width: 33.33%
   }
 }
 .col-3 {
   @media screen and (min-width: ${mq.tablet}) {
    width: 25%
   }
 }
 .col-2 {
   @media screen and (min-width: ${mq.tablet}) {
    width: 16.66%
   }
 }
 .col-1 {
   @media screen and (min-width: ${mq.tablet}) {
    width: 8.33%
   }
 }
`;

export default Grid;
