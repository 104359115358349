import React from 'react';
import styled from 'styled-components';
import _map from 'lodash/map';
import { social } from '../consts/data';
import { bz } from '../consts/style';

const SocialWrapper = styled.div`
  a {
    margin-right: 3.2rem;
    &:last-child {
      margin-right: 0;
    }
  }
  img {
    transition: 0.3s all;
    opacity: 1;
    ${bz};
    &:hover {
      opacity: 0.6;
    }
  }
`;

export default function Socials() {
  const els = _map(social, (item, i) => (
    <a
      target="blank"
      rel="nofollow noopener noreferrer"
      key={i}
      href={item.link}
    >
      <img src={item.icon} alt={item.title} />
    </a>
  ));
  return <SocialWrapper className="social-wrap">{els}</SocialWrapper>;
}
