import { createGlobalStyle } from 'styled-components';

const Global = createGlobalStyle`

html { font-family: 'Calibre', sans-serif; }

@supports (font-variation-settings: normal) {
  html { font-family: 'Inter var', sans-serif; }
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

`;

export default Global;
