import InstaSrc from '../images/social-instagram.svg';
import FacebookSrc from '../images/social-facebook.svg';

import TennisMP4 from '../videos/tennis.mp4';
import TennisWEBM from '../videos/tennis.webm';
import TennisPOST from '../images/tennis.jpg';

import GolfMP4 from '../videos/golf.mp4';
import GolfWEBM from '../videos/golf.webm';
import GolfPOST from '../images/golf.jpg';

import PoolMP4 from '../videos/pool.mp4';
import PoolWEBM from '../videos/pool.webm';
import PoolPOST from '../images/pool-cover.jpg';

import HoleMP4 from '../videos/hole.mp4';
import HoleWEBM from '../videos/hole.webm';
import HolePOST from '../images/hole.jpg';

export const videoModal = {
  id: '359883342',
};

export const hero = [
  {
    mp4: HoleMP4,
    webm: HoleWEBM,
    poster: HolePOST,
    image: 'hole',
    title: 'Reset',
    body:
      'Surround yourself in nature’s ultimate hideaway—a 400-acre pristine coastal canyon',
  },
  {
    mp4: TennisMP4,
    webm: TennisWEBM,
    poster: TennisPOST,
    image: 'tennis',
    title: 'Relax',
    body:
      'A seamless experience where world-class amenities exist at your fingertips',
  },
  {
    mp4: GolfMP4,
    webm: GolfWEBM,
    poster: GolfPOST,
    image: 'golf',
    title: 'Reside',
    body: 'Full ownership on the grounds of the prestigious Grand Del Mar',
  },
];

export const realtor = {
  name: 'Angela Meakins',
  title: 'Director of Sales',
  dre: 'DRE 01459726',
  phone: '858-405-9270',
};
export const realtor2 = {
  name: 'Kristofor Carnegie',
  title: 'Sales Associate',
  dre: 'DRE 01930535',
};

export const inquire = {
  body1: 'For Pricing and',
  body2: 'HOA Information',
  cta: 'CALL: 858.405.9270',
};

export const header = {
  menuItems: [
    { title: 'Home', link: '/' },
    { title: 'Inquire', link: null },
    { title: 'Residences', link: '/residences' },
    { title: 'Lifestyle', link: '/lifestyle' },
  ],
};

export const social = [
  {
    title: 'Instagram',
    link: 'https://www.instagram.com/penroseatthegrand/',
    icon: InstaSrc,
  },
  {
    title: 'Facebook',
    link: 'https://www.facebook.com/PenroseAtTheGrand/',
    icon: FacebookSrc,
  },
];

export const home = {
  heading: {
    title: 'Welcome To Penrose',
    body:
      'Designed to inspire, Penrose architecture and interiors boast a modern take on the Neoclassical grandeur of the Grand Del Mar.',
  },
  slider1: {
    label: 'RESIDENCES',
    title: 'Impossibility In Its Purest Form',
    body:
      'Handmade clay tiles crown the rooftops of Penrose offering a warmth of rust color in alignment with the smooth ivory stuccowalls. Aged bronze finishes outline the delicate details from mullions of the windows and French doors to the outdoor stair rails that lead you directly to your private Master Bedroom retreat.',
    cta: 'Discover Penrose',
    ctaTo: '/residences',
    slides: [
      {
        image: {},
        file: 'Home_Carousel1_Residence_01.jpg',
      },
      {
        image: {},
        file: 'Home_Carousel1_Residence_02.jpg',
      },
    ],
  },
  slider2: {
    label: 'Lifestyle',
    title: 'Placed On A Grand Canvas',
    body:
      'Penrose Owners, should they feel the need to venture out of their private haven, enjoy the proximity of the Grand Del Mar’s collection of world-class amenities. Your backyard encompasses a 21,000 square foot spa, tennis courts, a croquet lawn, and is accented by picturesque swimming pools. Together, these triple five star amenities are anchored by a superb Tom Fazio-designed golf course.',
    cta: 'Explore The Grand',
    ctaTo: '/lifestyle',
    slides: [
      {
        image: {},
        file: 'Home_Carousel2_Grand_01.jpg',
      },
      {
        image: {},
        file: 'Home_Carousel2_Grand_02.jpg',
      },
      {
        image: {},
        file: 'Home_Carousel2_Grand_03.jpg',
      },
      {
        image: {},
        file: 'Home_Carousel2_Grand_04.jpg',
      },
    ],
  },
  textSlider: {
    title: '[ pĕn’rōz ]',
    slides: [
      {
        title: 'Seamless',
        body:
          'A seamless blend of home and stunning landscape, where relaxation is perfectly balanced with an active lifestyle. Frequently seen as Penrose triangle, these seemingly impossible shapes possess a unique balance of inside and outside, where the observer cannot distinguish one quality from the other.',
      },
      {
        title: 'Impossible',
        body:
          'An impossible object. Represented in two-dimension, it refers to a three-dimensional form that cannot exist. Once referred to as “impossibility in its purest form”, Penrose is unbelievable at first glance.',
      },
      {
        title: 'Balance',
        body:
          'The name’s syllables ‘pen’ and ‘rose’ both share qualities with the homes of this community. ‘Pen’ commonly symbolizes intellect and creative expression. Contrast this with ‘rose,’ a symbol of beauty, balance, and relaxation. This name speaks to the qualities of these homes in many ways.',
      },
    ],
  },
  videoBlock: {
    title: 'Invest in Exclusivity',
    cta: 'Download Brochure',
    brochure: '/downloads/penrose-brochure.pdf',
  },
};

export const residences = {
  heading: {
    title: 'Call The 18th Home',
    body:
      'Your new home is surrounded by lush fairways that wind through a serene canyon preserve of the Grand Del Mar.',
  },
  hero: {
    content: {
      title: 'Where Probabilities Become Possibilities',
      body:
        'Preeminently positioned amidst exquisite architecture and world-class amenities, Penrose is a destination unto itself. Designed to inspire, Penrose architecture and interiors boast a modern take on the Neoclassical grandeur of the Grand Del Mar. These generously proportioned residences provide an abundance of possibilities from quiet relaxation to gracious entertaining, both indoors and out.',
    },
  },

  slider2: {
    content: {
      title: 'Interior',
      body:
        'Enjoy a private view of the 18th from the comfort of your living room. European oak planks span the interior space underfoot while rough hewn walnut beams grace the ceilings. Calacatta porcelain slabs top a wire brushed oak island forming a beautiful balance between provincial and modern design. The deep charcoal kitchen cabinetry offers a dramatic anchor to the white glazed ceramic subway tiles of the backsplash. Generously proportioned sofa seating creates an effortless California casual vibe, while modern Italian light fixtures give the space a delicate contemporary touch.',
    },
    data: [
      {
        image: {},
      },
      {
        image: {},
      },
      {
        image: {},
      },
      {
        image: {},
      },
    ],
  },
};

export const lifestyle = {
  heading: {
    title: 'A Triple Five-Star Canvas',
    body:
      'Penrose and its exclusive offerings are made possible by the grounds on which they stand, the illustrious Grand Del Mar.',
  },
  quote: {
    author: 'JM Barrie',
    body:
      '"You must have been warned against letting the golden hours slip by; but some of them are golden only because we let them slip by."',
  },
  infoBlock: {
    body:
      'With 10 courses on Golf Digest’s list of ‘America’s 100 Greatest Golf Courses’ and 18 layouts gracing Golfweek’s ‘Best top 100 Modern Courses’ list, Fazio’s preeminent status is beyond dispute.',
    slides: [
      [
        'Fazio’s design accommodates golfers of all levels; play is very fair for mid- and high-handicap players, all the while not compromising the challenge for the low handicapper',
        'PGA certified and professionally trained staff use state-of-the-art video equipment for private and group lessons',
        'Golf Carts equipped with Visage GPS System',
      ],
      [
        'Optional Forecaddie program for an enhanced on-course experience',
        'Bunker sand is a Palm Springs marble crush mix, giving a dramatic white appearance against the dark green grass of the course',
        'Painted cups and daily hand-raked bunkers ',
      ],
      [
        'Professional club fitting with launch monitor for TaylorMade, Titleist, PXG and Callaway Variety of tee locations spanning from 4,838 to 7,160 yards in length ',
        'Top 50 Private Course-rated practice facility ',
        'Three short-game practice areas, allowing for undisturbed practice times',
      ],
    ],
  },
  slider1: {
    content: {
      title: 'Life On The Links',
      body:
        'Tucked in a pristine coastal valley on the Los Peñasquitos Canyon Preserve, The Grand Golf Club’s dramatic elevations and diverse bunkering pay homage to the area’s unique topography. As San Diego’s only Tom Fazio designed golf course, players enjoy multiple tee boxes for all levels of play, and stunning vistas of the native chaparral-cloaked hillsides and tree-topped ridgelines.',
    },
    data: [
      {
        image: {},
        file: 'Lifestyle_Carousel1_Golf_01',
      },
      {
        image: {},
        file: 'Lifestyle_Carousel1_Golf_02',
      },
      {
        image: {},
        file: 'Lifestyle_Carousel1_Golf_03',
      },
      {
        image: {},
        file: 'Lifestyle_Carousel1_Golf_04',
      },
      {
        image: {},
        file: 'Lifestyle_Carousel1_Golf_05',
      },
    ],
  },
  slider2: {
    content: {
      title: 'Amenity Now',
      body:
        'Relax in repose in a palatial setting of revivalist Renaissance comfort complete with crystal glass tiles, marble arches, and Old World artwork. Perhaps the day calls for an intimate indoor body wrap or an outdoor massage graced by the breeze of the ocean. Or maybe the day warrants you to stay in your robe and let The Spa come to you.',
    },
    data: [
      {
        image: {},
        file: 'Lifestyle_Carousel2_Amenities_01',
      },
      {
        image: {},
        file: 'Lifestyle_Carousel2_Amenities_02',
      },
      {
        image: {},
        file: 'Lifestyle_Carousel2_Amenities_03',
      },
      {
        image: {},
        file: 'Lifestyle_Carousel2_Amenities_04',
      },
      {
        image: {},
        file: 'Lifestyle_Carousel2_Amenities_05',
      },
    ],
  },
  slider3: {
    content: {
      title: 'Tantalize Your Taste',
      body:
        'A place for every palate. Owners and their guests are invited to dine at one of the many venues on the Grand Del Mar’s grounds. Staples like the Clubhouse Grill offer the classics while Amaya will whisk you off to the coast of Italy, as you enjoy Californian cuisine with Italian influences. The resort’s signature restaurant, Addison, is San Diego’s first and only Michelin-starred restaurant. Summoning Chef William Bradley’s meticulous approach to the craft of cooking, the Addison provides a highly coveted culinary experience.',
    },
    data: [
      {
        image: {},
        file: 'Lifestyle_Carousel3_Dining_01',
      },
      {
        image: {},
        file: 'Lifestyle_Carousel3_Dining_02',
      },
      {
        image: {},
        file: 'Lifestyle_Carousel3_Dining_03',
      },
      {
        image: {},
        file: 'Lifestyle_Carousel3_Dining_04',
      },
    ],
  },
  slider4: {
    content: {
      title: 'On & Off The Ground',
      body:
        'We challenge you to let those golden hours slip by when surrounded by miles of scenic trails to hike, bike or traverse on horseback. Should you have your fill of fitness classes at the Grand Del Mar’s state of the art fitness center and its daily offerings of yoga and meditation, you’ll be delighted to know enchanting interruptions lay just beyond the grounds.',
    },
    data: [
      {
        image: {},
        file: 'Lifestyle_Carousel4_Grounds_01',
      },
      {
        image: {},
        file: 'Lifestyle_Carousel4_Grounds_02',
      },
      {
        image: {},
        file: 'Lifestyle_Carousel4_Grounds_03',
      },
      {
        image: {},
        file: 'Lifestyle_Carousel4_Grounds_04',
      },
    ],
  },
};
