import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { z, colors, bz } from '../consts/style';
import mq from '../style/mq';

const Button = styled.button`
  position: fixed;
  top: 6.6rem;
  right: 8rem;
  z-index: ${z.icon};
  height: 2.4rem;
  width: 2.4rem;
  background: transparent;
  border: none;
  ${mq.tablet`
    right: 5.5rem;
  `}
  ${mq.tabletSmall`
    top: 6.4rem;
    right: 4rem;
  `}
  ${mq.mobile`
    right: 2rem;
    top: 5.2rem;
  `}
  &:after {
    opacity: ${(props) => (props.faded ? '0' : '1')};
    transition: 0.5s all;
    ${bz};
    content: '';
    background: ${colors.orange};
    height: 0.2rem;
    width: 100%;
    position: absolute;
    left: 0;
    top: ${(props) =>
      props.open ? 'calc(50% - 0.1rem)' : 'calc(35% - 0.1rem)'};
    transform: rotate(${(props) => (props.open ? '45deg' : '0')});
  }
  &:before {
    opacity: ${(props) => (props.faded ? '0' : '1')};
    transition: 0.5s all;
    content: '';
    ${bz};
    background: ${colors.orange};
    height: 0.2rem;
    width: 100%;
    position: absolute;
    left: 0;
    top: ${(props) =>
      props.open ? 'calc(50% - 0.1rem)' : 'calc(70% - 0.1rem)'};
    transform: rotate(${(props) => (props.open ? '-45deg' : '0')});
  }
`;

export default function MenuIcon({ onClick, open }) {
  const [faded, setFaded] = useState(true);
  useEffect(() => {
    const unfade = setTimeout(() => {
      setFaded(false);
    }, 2500);
    if (typeof window !== 'undefined') {
      window.onload = () => {
        return () => clearTimeout([unfade]);
      };
    }
  }, []);
  return <Button faded={faded} onClick={onClick} open={open} />;
}

MenuIcon.propTypes = {
  open: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};
