import React, { useEffect, useState, useContext } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import heroContext from '../store/heroContext';
import PartSrc from '../images/logo-partial.svg';
import IconSrc from '../images/icon.svg';

import { z, bz } from '../consts/style';
import mq from '../style/mq';

const CenteredFull = css`
  width: 20rem;
  height: 9rem;
  top: calc(50% - 3.6rem);
  left: calc(50% - 10rem);
  .icon {
    width: 2.4rem;
    position: absolute;
    top: 4.4rem;
    left: calc(50% - 1.2rem);
  }
`;

const CenteredTopFull = css`
  width: 20rem;
  height: 9rem;
  top: 7.2rem;
  left: calc(50% - 10rem);
  .icon {
    width: 2.4rem;
    position: absolute;
    top: 4.4rem;
    left: calc(50% - 1.2rem);
  }
  ${mq.tabletSmall`
    top: 10rem;
  `}
`;

const CenteredTopIcon = css`
  width: 4rem;
  top: 5.6rem;
  left: calc(50% - 2rem);
  ${mq.tabletSmall`
    top: 5.8rem;
  `}
  ${mq.mobile`
   top: 5rem;
  `}
  .part {
    opacity: 0;
  }
  .icon {
    width: 2.8rem;
    position: absolute;
    top: 0rem;
    left: calc(50% - 1.4rem);
    ${mq.tabletSmall`
    width: 2.4rem;
    left: calc(50% - 1.2rem);
  `}
    ${mq.mobile`
    width: 2rem;
    left: calc(50% - .9rem);
  `}
  }
`;

const Wrapper = styled(Link)`
  transition: 1s all;
  ${bz};
  .part,
  .icon {
    transition: 1s all;
    ${bz};
  }
  z-index: ${z.logo};
  position: fixed;
  text-align: center;
  img {
    max-width: 100%;
  }
  ${(props) =>
    props.topped === 'true'
      ? props.full === 'true'
        ? CenteredTopFull
        : CenteredTopIcon
      : CenteredFull};
`;

export default function Logo({ location }) {
  const [topped, setTopped] = useState(false);
  const [done, setDone] = useState(false);
  const { fading } = useContext(heroContext);

  useEffect(() => {
    const topped = setTimeout(() => {
      setTopped(true);
    }, 1500);
    const animationDone = setTimeout(() => {
      setDone(true);
    }, 2500);
    if (typeof window !== 'undefined') {
      window.onload = () => {
        return () => clearTimeout([topped, animationDone]);
      };
    }
  }, []);

  function shouldBeFull() {
    const isHome = location && location.pathname === '/';
    if (!isHome && !topped) return true;
    if (fading) return false;
    return isHome;
  }

  function handleClick() {
    if (typeof window !== 'undefined' && window.location.pathname === '/') {
      window.location.reload();
    }
  }

  return (
    <Wrapper
      to="/"
      onClick={handleClick}
      done={done.toString()}
      full={shouldBeFull().toString()}
      topped={topped.toString()}
    >
      <img className="part" src={PartSrc} alt="Logo part 1" />
      <img className="icon" src={IconSrc} alt="Logo part 2" />
    </Wrapper>
  );
}

Logo.propTypes = {
  location: PropTypes.object.isRequired,
};
