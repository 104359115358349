import styled from 'styled-components';

import { z } from '../consts/style';
import mq from '../style/mq';

export const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 0 7.5rem;
  ${mq.tablet`
    padding: 0 5.5rem;
  `}
  ${mq.tabletSmall`
    padding: 0 4rem;
  `}
  ${mq.mobile`
    padding: 0 2rem;
  `}
`;

export const ContainerCompact = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 0 12.6rem;
  ${mq.tablet`
    padding: 0 5.5rem;
  `}
  ${mq.tabletSmall`
    padding: 0 4rem;
  `}
  ${mq.mobile`
    padding: 0 2rem;
  `}
`;

export const PageWrapper = styled.div`
  position: relative;
  z-index: ${z.page};
  background: white;
  padding-top: 8rem;
  margin-bottom: 36rem;
  ${mq.mobile`
  margin-bottom: 55.3rem;
  padding-top: 6rem;
`}
`;
